<template>
  <div class="bg-main training vh-100">
    <VuePullRefresh
      :key="pullKey"
      :on-refresh="appRefresh"
      :config="{ startLabel: '', readyLabel: '', loadingLabel: '', pullDownHeight: 60 }"
    >
      <v-container class="py-0 px-0 pb-10" fluid>
        <v-alert
          v-if="!$online"
          dense
          tile
          color="gray"
          icon="mdi-alert-circle-outline"
          class="alert-offline text-headline"
        >
          {{ $t("t_no_connection") }}
        </v-alert>

        <v-card flat class="bg-main" tile>
          <v-card-text class="d-flex pb-0" v-if="$platform == 'web'">
            <p class="headline mb-0">{{ $t("v_training.t_training_courses") }}</p>
            <v-spacer></v-spacer>
            <v-btn
              v-if="$vuetify.breakpoint.mdAndUp"
              icon
              class="ml-2"
              :class="{ rotate: this.$store.state.loader.loading }"
              @click="refresh"
              ><v-icon>mdi-refresh</v-icon></v-btn
            >
          </v-card-text>
          <TrainingCourseList ref="trainingCourseList" :takeNum="20" />
        </v-card>
      </v-container>
    </VuePullRefresh>
  </div>
</template>
<script>
import Helpers from "@/mixins/helpers";
import TrainingCourseList from "@/components/training/TrainingCourseList.vue";
import VuePullRefresh from "vue-pull-refresh";

export default {
  name: "TrainingCourses",
  metaInfo: {
    title: "Training Courses",
  },
  mixins: [Helpers],
  components: { VuePullRefresh, TrainingCourseList },
  data: () => ({
    pullKey: 0,
  }),
  methods: {
    refresh() {
      this.$refs.trainingCourseList.searchallCourses(true);
    },
    appRefresh() {
      this.$refs.trainingCourseList.searchallCourses(true);
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
          this.pullKey += 1;
        }, 1000);
      });
    },
  },
};
</script>
